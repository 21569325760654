<template>
    <hb-modal v-model="dialog" size="large" :title="selected?.id ? 'Edit Product' : 'Add Product'">
        <template v-slot:subheader>
            <span class="hb-text-light">
                Create 2-3 sentences that describes the overview of this form.
            </span>
        </template>
        <template v-slot:content>
            <hb-form label="Name" required>
                <hb-text-field 
                    v-model="productData.name" 
                    v-validate="'required|max:45'"
                    id="product_name"
                    name="product_name"
                    data-vv-name="product_name" 
                    data-vv-as="name" 
                    :error="errors.has('product_name')" 
                    placeholder="Enter Name" />
            </hb-form>

            <hb-form label="Product Image" full>
                <hb-empty-state>
                    <div class="image-upload" @drop.prevent="dropFilesEvent" @dragover.prevent="" @dragleave="">
                        <span>
                            <input type="file" accept="image/*" ref="ImageUploader" @change="uploadImage" multiple />
                            <label for="file-input">
                                <div class="upload-content">
                                    <hb-icon class="pb-2">mdi-image</hb-icon>
                                    <p class="upload-text-color pt-1 mb-2"><a class="click-to-upload" @click="$refs.ImageUploader.click()" >Click to upload</a> or drag and drop</p>
                                    <p class="upload-text-color-small">(square format)</p>
                                </div>
                            </label>
                        </span>
                    </div>
                </hb-empty-state>

                <div v-if="productData.Images.length" class="preview">
                    <div v-for="(img, index) in productData.Images" :key="index"
                        class="preview-img mb-3 preview-img-border">
                        <hb-btn icon @click="removeImage(index)" color="#637381" class="remove-button">mdi-close-circle</hb-btn>
                        <v-row class="mx-0">
                            <v-col md="3" sm="4"><img :src='"data:image/" + img.image_type + ";base64," + img.data_url' alt="Preview" /></v-col>
                            <v-col md="8"  sm="7" class="pr-0 pb-0">
                                <HbTextField v-model="img.name" :placeholder="'Name'" />
                                <HbTextField v-model="img.alt_tag" :placeholder="'Alt Tag'" />
                                <HbTextField v-model="img.caption" :placeholder="'Caption'" />
                                <hb-radio-group v-model="img.is_thumbnail" @change="setThumbnailEvent(index)"
                                    class="float-right"> 
                                    <hb-radio label="Set as thumbnail" :value="true"></hb-radio>
                                </hb-radio-group>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </hb-form>

            <hb-form label="SKU" required>
                <hb-text-field v-model="productData.sku" v-validate="'required|max:45'"
                    data-vv-name="product_sku" data-vv-as="sku" :error="errors.has('product_sku')" placeholder="Enter SKU" />
            </hb-form>

            <hb-form label="Description">
                <hb-textarea v-model="productData.description" v-validate="'max:1000'" data-vv-scope="default"
                    data-vv-name="description" data-vv-as="description" :error="errors.has('default.description')"
                    placeholder="Enter Description" />
            </hb-form>

            <hb-form label="Default Price" required>
                <hb-text-field condensed box medium v-model="productData.price" 
                    v-validate="'required|min_value:0'"
                    @change="fractionalValueEvent($event, 'price')"
                    id="product_price"
                    min="0"
                    name="product_price"
                    class="no-num-arrows"
                    type="number" data-vv-name="product_price" data-vv-as="price"
                    :error="errors.has('product_price')" placeholder="0.00">
                    <template v-slot:prepend-inner>
                        $
                    </template>
                </hb-text-field>
            </hb-form>

            <hb-form label="Default Cost">
                <hb-text-field 
                    @change="fractionalValueEvent($event, 'default_cost')"
                    condensed 
                    box 
                    medium 
                    v-model="productData.default_cost" 
                    type="number"
                    class="no-num-arrows"
                    v-validate="'min_value:0'"
                    id="default_cost"
                    min="0"
                    name="default_cost"
                    data-vv-name="default_cost" data-vv-as="default cost"
                    :error="errors.has('default_cost')" 
                    placeholder="0.00">
                    <template v-slot:prepend-inner>
                        $
                    </template>
                </hb-text-field>
            </hb-form>

            <hb-form label="Product Weight">
                <v-row>
                    <v-col md="7" class="py-0 mb-0">
                        <hb-select v-model="productData.weight_unit" 
                            v-validate="'required'"
                            id="weight_unit"
                            min="0"
                            name="weight_unit"
                            data-vv-name="weight_unit" 
                            data-vv-as="weight unit"
                            :clearable="false"
                            :error="errors.has('weight_unit')"
                            hide-details :items="['lbs', 'kgs']"
                            placeholder="Enter Weight" />
                    </v-col>
                    <v-col md="5" class="py-0">
                        <hb-text-field condensed box medium hide-details v-model="productData.weight"
                            type="number"
                            class="no-num-arrows"
                            v-validate="'required|min_value:0'"
                            id="product_weight"
                            min="0"
                            name="product_weight"
                            data-vv-name="product_weight" data-vv-as="weight"
                            :error="errors.has('product_weight')" 
                            placeholder="0" />
                    </v-col>
                </v-row>
            </hb-form>

            <hb-form label="Quantity per Package">
                <v-row no-gutters>
                    <div class="hb-inline-sentence-text pb-0 mb-0">
                        1 Package =
                    </div>
                    <hb-text-field class="mb-0 pb-0 no-num-arrows" condensed box small v-model="productData.quantity_per_package"
                        type="number"
                        v-validate="'min_value:0'"
                        id="quantity_per_package"
                        min="0"
                        name="quantity_per_package"
                        data-vv-name="quantity_per_package" data-vv-as="quantity per package"
                        :error="errors.has('quantity_per_package')" 
                        placeholder="1" />
                    <div class="hb-inline-sentence-text pb-0 mb-0">
                        product(s).
                    </div>
                </v-row>
            </hb-form>

            <hb-form label="Retail (Taxable)" required>
                <hb-radio-group  
                v-validate="'required'"
                data-vv-name="product_taxable" 
                data-vv-as="taxable" 
                :error="errors.has('product_taxable')"
                v-model="productData.taxable" row>
                    <hb-radio label="No" :value="false" />
                    <hb-radio label="Yes" :value="true" />
                </hb-radio-group>
            </hb-form>

            <hb-form label="GL Account" required>
                <hb-select v-model="productData.gl_account" :clearable="false" :items="glAccounts" v-validate="'required'"
                    placeholder="Select Account"
                    data-vv-name="gl_account" data-vv-as="gl account"
                    :error="errors.has('gl_account')" item-text="name" item-value="id" return-object />
            </hb-form>

            <hb-form label="Negative Inventory"
                description="Selling a product when its inventory is 0 will create negative inventory and will reflect in accounting.">
                <hb-checkbox v-model="productData.negative_inventory" label="Allow selling this product past 0 inventory" />
            </hb-form>

            <hb-form label="Assign Products to Properties">
                <hb-combobox v-model="productData.Properties" v-validate="'required'" :items="propertyItems" id="primary_properties"
                    name="primary_properties" label="Select Properties"
                    data-vv-name="primary_properties" data-vv-as="Properties"
                    :error="errors.has('primary_properties')" select item-value="id" item-text="name" return-object>
                </hb-combobox>
            </hb-form>

            <hb-form label="Inventory Threshold" full :auto-layout="false">
                <div class="inline-container">
                    <span>
                        When this product’s inventory is ≤
                    </span>
                    <hb-text-field class="mb-0 pb-0 no-num-arrows" condensed box small v-model="productData.inventory_threshold"
                        type="number"
                        v-validate="'min_value:0'"
                        id="inventory_threshold"
                        min="0"
                        name="inventory_threshold"
                        data-vv-name="inventory_threshold" data-vv-as="inventory_threshold"
                        :error="errors.has('inventory_threshold')" 
                        placeholder="10" />
                    <span>
                        , its status will change to “Low Stock”
                    </span>
                </div>
            </hb-form>

            <hb-form label="Target Inventory Amount"
                description="Set the product’s desired inventory to be reached when reordering. In the corporate settings, the minimum product inventory is set to the average of the last 3 months of sales. This can be changed to have a set amount in the property settings by clicking on edit product." />
        </template>

        <template v-slot:right-actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" :loading="isLoading($options.name)" @click="save">{{ selected ? 'Save' : 'Add Product' }}</hb-btn>
        </template>
    </hb-modal>
</template>

<script type="text/babel">
import Loader from "../../assets/CircleSpinner.vue";
import { mapGetters, mapActions } from 'vuex';
import api from "../../../assets/api";
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
    name: 'AddMerchandiseProduct',
    mixins: [ notificationMixin ],
    components: {
        Loader
    },
    props: {
        value: {
            type: Boolean
        },
        selected: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            productData: {
                name: '',
                Images: [],
                sku: '',
                description: '',
                price: '',
                default_cost: '',
                weight_unit: 'lbs',
                weight: '',
                quantity_per_package: '',
                gl_account: null,
                taxable: true,
                negative_inventory: true,
                Properties: [],
                inventory_threshold: ''
            },
            showAdd: true,
            uploading: false
        }
    },
    computed: {
        ...mapGetters({
            primaryRoleProperties: 'propertiesStore/primaryRoleProperties',
            glAccounts: 'accountingStore/glAccounts'
        }),
        propertyItems() {
            return this.primaryRoleProperties.map(p => {
                return {
                    id: p.id,
                    name: (p.number ? p.number + ' - ' : '') + (p.city ? p.city + ' - ' : '') + p.name,
                    number: p.number,
                    city: p.city,
                    gds_id: p.gds_id,
                    disabled: false,
                }
            })
        },
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    created() {
        this.getGLAccountsAction();
    },
    watch: {
        value: function (newVal) {
            if (newVal && this.selected) {
                this.productData = Object.assign(this.productData, this.selected);
                this.productData.gl_account = this.selected.income_account_id ? this.glAccounts.find(item => item.id === this.selected.income_account_id) ? this.glAccounts.find(item => item.id === this.selected.income_account_id) : null : null;
                let temp_Arr = this.selected.Properties.map(item => item.id);
                this.productData.taxable = this.selected ? true : false;
                this.productData.Properties = this.propertyItems.filter(item => temp_Arr.includes(item.id));
                this.productData.taxable = this.selected ? true : false;
                let tempImages = this.selected.Images && this.selected.Images.length ? this.selected.Images.map(item => {
                    return {
                        ...item,
                        is_thumbnail: item.is_thumbnail ? true : false

                    }
                }) : [];
                this.productData.Images = tempImages;

            } else {
                if(this.selected) {
                    this.$emit('reset-selected');
                }
                this.resetData();
            }
        }
    },
    methods: {
    ...mapActions({
            getGLAccountsAction: 'accountingStore/getGLAccountsAction'
        }),
        resetData() {
            this.productData = {
                name: '',
                Images: [],
                sku: '',
                description: '',
                price:'',
                default_cost: '',
                weight_unit: 'lbs',
                weight: '',
                quantity_per_package: '',
                gl_account: null,
                taxable: true,
                negative_inventory: true,
                Properties: [],
                inventory_threshold: ''
            }
        },
        async uploadImage(e) {
            this.uploading = true;
            let files = e?.target?.files ? e.target.files : e;
            let sizeCheck = Object.keys(files).find(item => files[item].size > 5000000);
            if (sizeCheck) {
                this.showMessageNotification({ description: 'Files must be smaller than 5MB'})
                e.target.value = [];
                return;
            }
            let typeCheck = Object.keys(files).find(item => !files[item].type.includes('image'));
            if (typeCheck) {
                this.showMessageNotification({ description: 'Selected files must be of type image'});
                e.target.value = [];
                return;
            }
            let images = [];
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                // let image = await this.readFile(file);
                let image = (await this.readFile(file)).split(',')[1];
                // let extension = image.charAt(0);
                // image = "data:image/" + (extension === 'i' ? 'png' : 'jpg') + ";base64," + image;
                this.productData.Images.push({
                    data_url: image,
                    aws_image_name: '',
                    name: file.name,
                    alt_tag: '',
                    caption: '',
                    image_type: file.type.split("/")[1],
                    is_thumbnail: !this.productData.Images.length ? true : false
                });
            }
            if(e.target) e.target.value = [];
            else e = [];
        },
        readFile(file){
            try {
                return new Promise((resolve, reject) => {
                    var fr = new FileReader();  
                    fr.onload = () => {
                        resolve(fr.result)
                    };
                    fr.onerror = reject;
                    fr.readAsDataURL(file);
                });
            } catch (error) {
                this.showMessageNotification({ description: error })
            }
        },
        setThumbnailEvent(index) {
            this.productData.Images.forEach((element, ind) => {
                if (index === ind) element.is_thumbnail = true;
                else element.is_thumbnail = false;
            });
        },
        removeImage(index) {
            // Remove the image from the preview array
            this.productData.Images.splice(index, 1);
        },
        fractionalValueEvent(params,field) {
            this.productData[field] = parseFloat(params).toFixed(2);
        },
        async save() {
            const validationStatus = await this.$validator.validateAll();
            if (validationStatus) {
                try {
                    let payload = {
                        "name": this.productData.name,
                        "sku": this.productData.sku,
                        "taxable": this.productData.taxable,
                        "amount_type": "fixed",
                        "price": this.productData.price,
                        "description": this.productData.description,
                        "type": "product",
                        "default_type": "product",
                        "Properties": this.productData.Properties.map(item => {
                            return {
                                id: item.id
                            }
                        }),
                        "weight_unit": this.productData.weight_unit,
                        "weight": this.productData.weight,
                        "default_cost": this.productData.default_cost,
                        "quantity_per_package": this.productData.quantity_per_package,
                        "negative_inventory": this.productData.negative_inventory,
                        "inventory_threshold": this.productData.inventory_threshold,
                        "Images": this.productData.Images,
                        "gl_account_code": this.productData.gl_account.code,
                        "gl_account_name": this.productData.gl_account.name,
                        "gl_account_active": 1,
                        "income_account_id": this.productData.gl_account.id
                    }
                    let response = "";
                    if (this.selected?.id) {
                        response = await api.put(this, api.MERCHANDISE + this.selected.id, payload);
                    } else {
                        response = await api.post(this, api.MERCHANDISE, payload);
                    }
                    this.showMessageNotification({ type: 'success', description: 'Product saved successfully!' });
                    this.$emit('close');
                    this.$emit('fetchProducts');
                } catch (error) {
                    this.showMessageNotification({ description: error });
                }
            }
        },
        dropFilesEvent(event) {
            this.uploadImage(event.dataTransfer.files);
        }
    }
}
</script>

<style lang="scss" scoped>
.inline-flex {
    display: inline-flex !important;
}

.left-border {
    border-left: 1px solid #ccc;
    /* Adjust color and thickness as needed */
}

.display-none {
    display: none;
}

.modal-form-bg-color {
    background: #F4F6F8;
}

.font-weight-500 {
    font-weight: 500;
}

/* Add styles for preview section */
.preview {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.preview-img {
    margin-right: 10px;
    position: relative;
    width: 100%;
}

.preview img {
    max-width: 100%;
    max-height: 100%;
}

.remove-button {
    position: absolute;
    top: 0;
    right: 0;
}

/* Rest of the styles remain the same */

.image-upload input[type="file"] {
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // opacity: 0;
    // cursor: pointer;
    display: none;
}

.uploading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-upload {
    font-size: 48px;
    color: #ccc;
    margin-bottom: 10px;
}

.click-to-upload {
    color: #00848E;
    text-decoration: underline;
    cursor: pointer;
}

.upload-text-color-small {
    color: #637381;
   font-size: 12px;
}

.upload-text-color {
   color: #637381;
   font-size: 14px;
}

.preview-img-border {
    border: 1px solid #DFE3E8;
    border-radius: 4px;
    margin: 0;
}

.flex-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.no-data-box {
    padding: 30px 0;
    text-align: center;
    border: 1.5px dashed #ccc;
    border-radius: 3px;
}

.display-flex {
    display: flex;
}

.inline-container {
    white-space: normal;
}

.inline-container > span {
    display: inline;
    position: relative;
    top: -4px;
}

.inline-container > div {
    display: inline-block;
    padding: 2px 4px;
    margin: 0 2px;
}
</style>

